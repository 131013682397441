import EvoMarkdown from '@/components/Md.vue';
import PrivacyPolicy from '@/views/legal/markdown/PrivacyPolicy.md';
import DataProtectionPolicy from '@/views/legal/markdown/DataProtectionPolicy.md';
import AcceptableUsePolicy from '@/views/legal/markdown/AcceptableUsePolicy.md';
import ProhibitedGoodsServices from '@/views/legal/markdown/ProhibitedGoodsServices.md';

export const legalHomeMixin = {
  data() {
    return {
      items: [
        {
          title: 'legal.title_1',
          date: '25th April, 2022',
          desc: 'legal.desc_1',
          link: '/company-policy/privacy-policy',
          linkText: 'read_more_upper'
        },
        {
          title: 'legal.title_2',
          date: '25th April, 2022',
          desc: 'legal.desc_2',
          link: '/company-policy/acceptable-use-policy',
          linkText: 'read_more_upper'
        },
        {
          title: 'legal.title_3',
          date: '25th April, 2022',
          desc: 'legal.desc_3',
          link: '/company-policy/data-protection-policy',
          linkText: 'read_more_upper'
        },
        {
          title: 'legal.title_4',
          date: '25th April, 2022',
          desc: 'legal.desc_4',
          link: '/company-policy/prohibited-goods-services',
          linkText: 'read_more_upper'
        }
      ]
    };
  },
  methods: {
    goTo(link) {
      this.$router.push(link).catch(() => {});
    }
  }
};

export const legalDetailMixin = {
  components: {
    EvoMarkdown
  },
  data: function () {
    return {
      itemsMap: {
        PrivacyPolicy: {
          title: 'legal.privacy_policy',
          desc: 'legal.title_1',
          link: '/company-policy/privacy-policy',
          md: PrivacyPolicy
        },
        AcceptableUsePolicy: {
          title: 'legal.privacy_policy',
          desc: 'legal.title_2',
          link: '/company-policy/acceptable-use-policy',
          md: AcceptableUsePolicy
        },
        DataProtectionPolicy: {
          title: 'legal.privacy_policy',
          desc: 'legal.title_3',
          link: '/company-policy/data-protection-policy',
          md: DataProtectionPolicy
        },
        ProhibitedGoodsServices: {
          title: 'legal.privacy_policy',
          desc: 'legal.title_4',
          link: '/company-policy/prohibited-goods-services',
          md: ProhibitedGoodsServices
        }
      }
    };
  },
  computed: {
    selectedItem() {
      const name = this.$route.name;
      return this.itemsMap[name] || {};
    },
    md() {
      return this.selectedItem.md;
    },
    items() {
      const name = this.$route.name;
      return Object.keys(this.itemsMap)
        .filter(key => key !== name)
        .map(key => this.itemsMap[key]);
    },
    breadcrumbs() {
      return [
        {
          text: this.$t('legal.title'),
          disabled: false,
          href: '/company-policy'
        },
        {
          text: this.$t(this.selectedItem.desc),
          disabled: true
        }
      ];
    }
  }
};
